.draggingList
  overflow-y: auto
  height: 505px
  & > div
    max-width: fit-content
  & .item
    display: flex
    align-items: center
    justify-content: flex-start
    width: 100% !important
    padding: 8px
    & .drag-icon
      margin-right: 14px
      display: block
      width: 10px
      height: 16px
      z-index: 20
      &:before
        background-repeat: no-repeat
        background-size: contain
        width: 10px
        height: 16px
        display: flex
    &:hover
      .drag-icon:before
        content: url("@assets/img/icon/dragHover.svg")
      & .checkBox
        color: #1890FF
        & .checkmark,  & .checkmark:after
          border-color: #1890FF


body > #currencySortableItem
  display: flex
  align-items: center
  justify-content: flex-start
  z-index: 1002
  height: min-content !important
  background: #E6F7FF 0 0 no-repeat padding-box
  border-radius: 13px
  padding: 5px 8px
  margin: 0
  & .drag-icon
    margin-right: 14px
    height: 16px
    &:before
      content: url("@assets/img/icon/dragHover.svg")
  & .checkBox
    & .checkmark,  & .checkmark:after
      border-color: #1890FF