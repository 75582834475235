.translate
  & .toolBar
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 26px
    & .label
      display: flex
      flex-direction: row
      flex-wrap: wrap
      text-align: left
      font:  14px/22px "SF Pro Display Regular"
      letter-spacing: 0
      min-width: 260px
      & .ant-select.ant-select-single.ant-select-show-arrow
        width: 100% !important
    & .btnBox
      & .miniBtn
        padding: 10px 34px
        text-align: center
        font: 14px/24px "SF Pro Display Regular"
        letter-spacing: 0
        line-height: 16px
        &.default
          margin-right: 8px


  & .ant-table .ant-table-container
    &::before, &::after
        display: none
    & table
      table-layout: auto !important
    & .ant-table-tbody
        & tr
          & td:first-child
            padding: 14px 60px 14px  16px
        & .iconBox
          justify-content: flex-start
          font: 14px/19px "Red Hat Display Regular"
          & .uploadDescription
            display: flex
            align-items: flex-end
            justify-content: flex-start
            visibility: hidden
            width: 0
            & .icon
              cursor: pointer
            &.loader
              flex-direction: row-reverse
            &.visibleElement
              visibility: visible
              width: auto
          & .uploadFileContent
            background: transparent
            max-height: unset
            margin: 5px
            height: auto
          & .ant-upload.ant-upload-drag
              background: transparent !important
              max-height: unset
              border: transparent
              height: auto
              outline: 0
          & .uploadFileContent
              & .ant-upload.ant-upload-drag .ant-upload,
              & .ant-upload-drag-container
                background: transparent
          & .upload
            display: flex
            align-items: center
            justify-content: flex-start
            & .uploadFileContent
              & span
                & .ant-upload.ant-upload-drag.preview-uploader
                  & .ant-upload.ant-upload-btn
                    & .ant-upload-drag-container
                      display: flex
                      align-items: center
                      flex-direction: row-reverse
                      color: $project-global-color
            & .icon
              margin: 5px
              &.loading
                -webkit-animation: rotating 2s linear infinite
                -moz-animation: rotating 2s linear infinite
                -ms-animation: rotating 2s linear infinite
                -o-animation: rotating 2s linear infinite
                animation: rotating 2s linear infinite


          &.existing
            flex-direction: unset
            & .upload
              cursor: auto
              text-align: left
              font: 14px/19px "Red Hat Display Regular"
              letter-spacing: 0
              text-decoration: unset
              & span
                display: block
                width: 107px
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

          & .upload
            text-align: left
            text-decoration: underline
            font: 14px/19px "Red Hat Display Regular"
            letter-spacing: 0
            width: 107px
            margin: 0 8px 0 0
            cursor: pointer



.imgTooltip.ant-tooltip
  & .ant-tooltip-content
    & .ant-tooltip-inner
      padding: 0
      & .toolTipBg
        width: 150px
        height: 150px
        border-radius: 4px
        object-fit: contain



@-webkit-keyframes rotating /* Safari and Chrome */
  from
    -webkit-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)

  to
    -webkit-transform: rotate(360deg)
    -o-transform: rotate(360deg)
    transform: rotate(360deg)

@keyframes rotating
  from
    -ms-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -webkit-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)

  to
    -ms-transform: rotate(360deg)
    -moz-transform: rotate(360deg)
    -webkit-transform: rotate(360deg)
    -o-transform: rotate(360deg)
    transform: rotate(360deg)