.uploadFileContent
    position: relative
    background: $img-background-color 0% 0% no-repeat padding-box
    display: flex
    flex-direction: column
    justify-content: center
    height: 400px
    overflow: hidden
    &.uploaded
        & .ant-upload.ant-upload-drag
            background: $img-bg-color 0% 0% no-repeat padding-box
            border: transparent
            outline: 0
        & .ant-upload-drag-container
           vertical-align: top !important
    &--img
        width: 100%
        object-fit: cover
        padding: 20px
        height: 334px
    &--infoBox
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        background: $img-info-bg-color 0 0 no-repeat padding-box
        border-radius: 0 0 2px 2px
        padding: 16px 18px
        &--title
            text-align: left
            font: 14px/18px "SF Pro Display Regular" 
            letter-spacing: 0
            color: #ffffff
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            width: 235px
        &--btnGroup
            display: flex
            align-items: center
            & button
                background: transparent
                border: transparent
                padding: 5px
                cursor: pointer
                color: #fff
                & .delete-upload-icon, & .anticon
                    font-size: 18px
                    margin: 0 5px
                & .delete-upload-icon
                    &:before
                        width: 16px
                        background-image: url("@assets/img/icon/deleteImg2.svg")
                & .text
                    display: none
                &:hover,&:active,&:focus
                   background: transparent
                   color: #fff




    & .ant-upload.ant-upload-drag .ant-upload
        padding: 0
    .ant-upload-drag-container
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
