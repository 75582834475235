.modalLoader
  padding: 17px 0 0 0
  & .modalHeader
    display: flex
    align-items: center
    justify-content: space-between
    padding: 17px 24px
    & .title
      width: 154px
      height: 24px
      background: #F2F2F7 0 0 no-repeat padding-box
      border-radius: 4px
      opacity: 1
      @include background-gradient
      animation: shine-lines $animation-duration infinite linear
    & .close
      width: 16px
      height: 16px
      background: #F2F2F7 0 0 no-repeat padding-box
      border-radius: 4px
      opacity: 1
      @include background-gradient
      animation: shine-lines $animation-duration infinite linear
  & .tabs
    margin: 0 24px 24px 24px
    width: auto
    height: 30px
    background: #F2F2F7 0 0 no-repeat padding-box
    border-radius: 4px
    @include background-gradient
    animation: shine-lines $animation-duration infinite linear
  & .modalContent
    margin: 0 24px 24px 24px
    & .modalLoaderCol
      width: 100%
      &.listItem
        margin-right: 10px
      &.mainCol
        padding: 0  24px
      &.leftSide
        border-right: 1px solid #1A183B33

      & .label
        width: 35px
        height: 16px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        margin-bottom: 4px
        @include background-gradient
        animation: shine-lines $animation-duration infinite linear
      & .input
        width: 100%
        height: 36px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        padding: 12px
        margin-bottom: 17px
        &.smallMargin
          margin-bottom: 9px
        & .value
          width: 65px
          height: 12px
          background: #FFFFFF 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
      & .addBtn
        width: 36px
        height: 36px
        background: #F2F2F7 0 0 no-repeat border-box
        border-radius: 4px
        opacity: 1
        margin: 0 0 17px 7px

      & .labelMiddle
        width: 77px
        height: 16px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        @include background-gradient
        animation: shine-lines $animation-duration infinite linear
      & .image
        width: 100%
        height: 400px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        margin-top: 4px
        & .icon
          width: 31px
          height: 31px
          background: #FFFFFF 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          margin-bottom: 19px
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
        & .title
          width: 143px
          height: 12px
          background: #FFFFFF 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          margin-bottom: 5px
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
        & .description
          width: 222px
          height: 12px
          background: #FFFFFF 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
          background-size: 1000px
      & .labelLarge
        width: 93px
        height: 16px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        @include background-gradient
        animation: shine-lines $animation-duration infinite linear
      & .item
        width: 100%
        height: 39px
        display: flex
        align-items: center
        justify-content: flex-start
        &:first-child
          margin-top: 18px
        & .drag
          width: 30px
          height: 20px
          background: transparent url('@assets/img/icon/drag.svg') 0 0 no-repeat padding-box
        & .icon
          width: 18px
          height: 18px
          background: #F2F2F7 0 0 no-repeat padding-box
          border-radius: 2px
          opacity: 1
          margin-right: 11px
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
        & .title
          width: 63px
          height: 16px
          background: #F2F2F7 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
      & .periods
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 6px
        & .period
          width: 314px
          height: 36px
          background: #F2F2F7 0 0 no-repeat padding-box
          border-radius: 4px
          padding: 12px
          opacity: 1
          display: flex
          align-items: center
          justify-content: space-between
          & .date
            width: 106px
            height: 12px
            background: #FFFFFF 0 0 no-repeat padding-box
            border-radius: 4px
            opacity: 1
            @include background-gradient
            animation: shine-lines $animation-duration infinite linear
          & .circle
            width: 13px
            height: 12px
            background: #FFFFFF 0 0 no-repeat padding-box
            border-radius: 4px
            @include background-gradient
            animation: shine-lines $animation-duration infinite linear
      & .avgRange
        display: flex
        align-items: center
        justify-content: flex-start
        margin-bottom: 14px
        & .switch
          background: #F2F2F7 0 0 no-repeat padding-box
          width: 51px
          height: 31px
          opacity: 1
          border-radius: 50px
          margin-left: 10px
          position: relative
          & .switchBtn
            width: 30px
            height: 30px
            border-radius: 100%
            background: #FFFFFF 0 0 no-repeat padding-box
            position: absolute
            right: 1px
            top: 0
            @include background-gradient
            animation: shine-lines $animation-duration infinite linear
      & .slider
        & .line
          width: 100%
          height: 6px
          background: #F2F2F7 0 0 no-repeat padding-box
          opacity: 1
          position: relative
          margin-bottom: 8px
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
          & .circle
            width: 20px
            height: 20px
            border-radius: 100%
            background: #F2F2F7 0 0 no-repeat padding-box
            opacity: 1
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            margin: auto
            &:before
              content: ""
              width: 10px
              height: 10px
              border-radius: 100%
              background: #FFFFFF 0% 0% no-repeat padding-box
              opacity: 1
              position: absolute
              left: 0
              right: 0
              top: 0
              bottom: 0
              margin: auto
        & .axis
          display: flex
          align-items: center
          justify-content: space-between
          & .point
            width: 16px
            height: 16px
            background: #F2F2F7 0 0 no-repeat padding-box
            border-radius: 4px
            opacity: 1
            margin-bottom: 17px
            @include background-gradient
            animation: shine-lines $animation-duration infinite linear
  & .modalLoaderRow
    display: flex
    align-items: stretch
    justify-content: space-between
    flex-direction: row
    flex-wrap: nowrap
    width: 100%
    &.threePart
      align-items: flex-end
      & .modalLoaderCol
        &.smallInput
          width: calc(100% - 46px)

    & .modalLoaderCol
      width: 100%
      &.listItem
        margin-right: 10px


      &.mainCol
        padding: 0  24px
      &.leftSide
        border-right: 1px solid #1A183B33

      & .label
        width: 35px
        height: 16px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        margin-bottom: 4px
        @include background-gradient
        animation: shine-lines $animation-duration infinite linear
      & .input
        width: 100%
        height: 36px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        padding: 12px
        margin-bottom: 17px
        &.smallMargin
          margin-bottom: 9px
        & .value
          width: 65px
          height: 12px
          background: #FFFFFF 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
      & .addBtn
        width: 36px
        height: 36px
        background: #F2F2F7 0 0 no-repeat border-box
        border-radius: 4px
        opacity: 1
        margin: 0 0 17px 7px

      & .labelMiddle
        width: 77px
        height: 16px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        @include background-gradient
        animation: shine-lines $animation-duration infinite linear
      & .image
        width: 100%
        height: 400px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        margin-top: 4px
        & .icon
          width: 31px
          height: 31px
          background: #FFFFFF 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          margin-bottom: 19px
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
        & .title
          width: 143px
          height: 12px
          background: #FFFFFF 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          margin-bottom: 5px
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
        & .description
          width: 222px
          height: 12px
          background: #FFFFFF 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
          background-size: 1000px
      & .labelLarge
        width: 93px
        height: 16px
        background: #F2F2F7 0 0 no-repeat padding-box
        border-radius: 4px
        opacity: 1
        @include background-gradient
        animation: shine-lines $animation-duration infinite linear
      & .item
        width: 100%
        height: 39px
        display: flex
        align-items: center
        justify-content: flex-start
        &:first-child
          margin-top: 18px
        & .drag
          width: 30px
          height: 20px
          background: transparent url('@assets/img/icon/drag.svg') 0 0 no-repeat padding-box
        & .icon
          width: 18px
          height: 18px
          background: #F2F2F7 0 0 no-repeat padding-box
          border-radius: 2px
          opacity: 1
          margin-right: 11px
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
        & .title
          width: 63px
          height: 16px
          background: #F2F2F7 0 0 no-repeat padding-box
          border-radius: 4px
          opacity: 1
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
      & .periods
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 6px
        & .period
          width: 314px
          height: 36px
          background: #F2F2F7 0 0 no-repeat padding-box
          border-radius: 4px
          padding: 12px
          opacity: 1
          display: flex
          align-items: center
          justify-content: space-between
          & .date
            width: 106px
            height: 12px
            background: #FFFFFF 0 0 no-repeat padding-box
            border-radius: 4px
            opacity: 1
            @include background-gradient
            animation: shine-lines $animation-duration infinite linear
          & .circle
            width: 13px
            height: 12px
            background: #FFFFFF 0 0 no-repeat padding-box
            border-radius: 4px
            @include background-gradient
            animation: shine-lines $animation-duration infinite linear
      & .avgRange
        display: flex
        align-items: center
        justify-content: flex-start
        margin-bottom: 14px
        & .switch
          background: #F2F2F7 0 0 no-repeat padding-box
          width: 51px
          height: 31px
          opacity: 1
          border-radius: 50px
          margin-left: 10px
          position: relative
          & .switchBtn
            width: 30px
            height: 30px
            border-radius: 100%
            background: #FFFFFF 0 0 no-repeat padding-box
            position: absolute
            right: 1px
            top: 0
            @include background-gradient
            animation: shine-lines $animation-duration infinite linear
      & .slider
        & .line
          width: 100%
          height: 6px
          background: #F2F2F7 0 0 no-repeat padding-box
          opacity: 1
          position: relative
          margin-bottom: 8px
          @include background-gradient
          animation: shine-lines $animation-duration infinite linear
          & .circle
            width: 20px
            height: 20px
            border-radius: 100%
            background: #F2F2F7 0 0 no-repeat padding-box
            opacity: 1
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            margin: auto
            &:before
              content: ""
              width: 10px
              height: 10px
              border-radius: 100%
              background: #FFFFFF 0% 0% no-repeat padding-box
              opacity: 1
              position: absolute
              left: 0
              right: 0
              top: 0
              bottom: 0
              margin: auto
        & .axis
          display: flex
          align-items: center
          justify-content: space-between
          & .point
            width: 16px
            height: 16px
            background: #F2F2F7 0 0 no-repeat padding-box
            border-radius: 4px
            opacity: 1
            margin-bottom: 17px
            @include background-gradient
            animation: shine-lines $animation-duration infinite linear

  & .modalFooter
    padding: 17px 24px 0  24px
    border-top: 1px solid #1A183B33
    display: flex
    align-items: center
    justify-content: flex-end
    & .button
      width: 110px
      height: 36px
      background: #F2F2F7 0 0 no-repeat padding-box
      border-radius: 52px
      opacity: 1
      @include background-gradient
      animation: shine-lines $animation-duration infinite linear
      &:first-child
        margin-right: 8px




