.notData
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  flex: 1
  & .ant-empty-normal
    margin: 0
  & .infoBox
    &--title
      text-align: center
      font: 18px/23px "Red Hat Display Bold"
      color: $content-title-color

    &--description
      text-align: center
      font: 16px/23px "Red Hat Display Regular"
      color: $project-global-color

  & .btnGroup
    margin: 24px
