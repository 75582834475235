.alertMessage
  position: fixed
  top: 24px
  left: 0
  right: 0
  margin: auto
  width: fit-content
  min-width: 360px
  max-width: 1000px
  padding: 0 16px
  text-align: left
  font: 14px/22px "SF Pro Display Regular"
  letter-spacing: 0
  color: #FFFFFF
  z-index: 10000
  display: none

  &.show
    display: block

  & .msg
    display: flex
    align-items: center
    justify-content: flex-start
    margin: 5px
    padding: 5px 12px 5px 0
    &.in
      animation: fadeIn 1s
      -webkit-animation: fadeIn 1s
      -moz-animation: fadeIn 1s
      -o-animation: fadeIn 1s
      -ms-animation: fadeIn 1s
      &.out
        animation: fadeOut 1s
        -webkit-animation: fadeOut 1s
        -moz-animation: fadeOut 1s
        -o-animation: fadeOut 1s
        -ms-animation: fadeOut 1s


    &.error
      background: #AA193B 0 0 no-repeat padding-box
      border-radius: 4px

    &.info
      background: #00A1AF 0 0 no-repeat padding-box
      border-radius: 4px

    &.warning
      background: #F77148 0 0 no-repeat padding-box
      border-radius: 4px

    &.done
      background: #00CE7E 0 0 no-repeat padding-box
      border-radius: 4px
    & .icon
       margin: 0 12px 0 12px




@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@-moz-keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@-webkit-keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@-o-keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@-ms-keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes fadeOut
  0%
    opacity: 1
  100%
    opacity: 0

@-moz-keyframes fadeOut
  0%
    opacity: 1
  100%
    opacity: 0

@-webkit-keyframes fadeOut
  0%
    opacity: 1
  100%
    opacity: 0

@-o-keyframes fadeOut
  0%
    opacity: 1
  100%
    opacity: 0

@-ms-keyframes fadeOut
  0%
    opacity: 1
  100%
    opacity: 0
