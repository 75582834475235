.bets
  & .mainTable
    & .ant-table .ant-table-container
       &::before, &::after
          display: none
       & table
         table-layout: auto !important
         & .ant-table-tbody
           & > tr:nth-child(2n),
           & > tr:nth-child(2n) td
             background-color: transparent
           & tr:not(.ant-table-measure-row)
             & td
               padding: 0
               white-space: nowrap
               height: 100%
               cursor: default
               & span, & div:not(.ant-empty-image,.ant-empty-description)
                 padding: 14px 60px 14px  16px
                 height: 100%

.predictionDropDown
  & .ant-select-item-option-content
    display: flex
    align-items: center
    justify-content: flex-start
    & .icon
      margin-right: 5px