.trading-form
  & .ant-tabs-nav
    display: none
  & .selectWeekDays
    display: flex
    align-items: center
    justify-content: center
    margin: 20px 0
    & .selectDescription
      text-align: left
      font: 300 14px/22px "SF Pro Display Regular"
      letter-spacing: 0
      color: #898899
      margin: 0 15px


.range-time-picker
  & .ant-col.ant-form-item-control
    & .ant-form-item-control-input
      & .ant-form-item-control-input-content
        & .ant-picker.ant-picker-range
          font:  300 12px "SF Pro Display Regular"
          margin: 0 4px 0 0
          min-width: 250px
          max-width: 250px
          & .ant-picker-input
            font:  300 12px "SF Pro Display Regular"
            text-align: right
            &:first-child
              & input
                text-align: left
            & input
              font:  300 12px "SF Pro Display Regular"
              text-align: right
          & .ant-picker.ant-picker-range
            & input
              font:  300 12px "SF Pro Display Regular"
              width: auto
        & .ant-picker-range-separator
          padding: 0 2px


.ant-picker-range-arrow::after
  border: 1px solid
  border-color: #DCDCE4 #DCDCE4 transparent transparent
  width: 10px
  height: 10px
  top: 0
  background: #fff

.tradingRatesPeriods
  & .ant-picker-panel-container
    background: #FFFFFF 0 0 no-repeat padding-box
    border: 1px solid #DCDCE4
    width: 121px
    & .ant-picker-time-panel
      width: 121px
      & .ant-picker-time-panel-column
        width: 37px
        text-align: center
        & .ant-picker-time-panel-cell-inner
          padding: 0
          font: 300 14px/22px "SF Pro Display Regular"
          letter-spacing: 0
          color: #191738

    & .ant-picker-footer
      & .ant-picker-ranges
        & .ant-picker-ok
          & .ant-btn.ant-btn-primary.ant-btn-sm
            background: #F8F8FA 0 0 no-repeat padding-box
            border-color: #F8F8FA
            border-radius: 52px
            text-align: center
            font: 14px/24px "SF Pro Display Regular"
            letter-spacing: 0
            color: #11121F



.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner
  background: #F8F8FA 0 0 no-repeat padding-box

.periods
  &Box
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    & .pickerBox
      display: flex
      align-items: flex-start
      & .errorMsg
        text-align: center
        padding: 5px 0 0 0
        color: #ff4d4f
      & .ant-picker-range
        width: 40%
    & .range-time-picker
      display: flex
      align-items: flex-start
      justify-content: flex-start
      flex-wrap: nowrap
      width: 100%
    & .selectedList
      display: flex
      flex-wrap: wrap
      align-items: flex-start
      justify-content: flex-start
      width: 100%
    & .dateItem
      background: $periods-background-color 0 0 no-repeat padding-box
      border: 1px solid $periods-border-color
      border-radius: 2px
      text-align: left
      font: 12px/24px "SF Pro Display Regular"
      letter-spacing: 0
      color:  $periods-text-color
      width: 134px
      margin: 0 4px 4px 0
      position: relative
      padding: 0 6px
      & .close
        position: absolute
        right: 10px
        top: 0
        bottom: 0
        margin: auto
        width: 10px
        height: 10px
        cursor: pointer
        &:before
          content: ""
          width: 1px
          height: 10px
          background-color: $project-global-color
          position: absolute
          transform: rotate(45deg)
          right: 0
          top: 0
        &:after
          content: ""
          width: 1px
          height: 10px
          background-color:  $project-global-color
          position: absolute
          transform: rotate(315deg)
          right: 0
          top: 0

