.defaultGames
  align-items: flex-start
  position: relative
  padding: 0 24px
  flex-wrap: wrap
  & .ant-space-item
    padding: 0
    & .ant-form-item:not(.ant-form-item-has-error)
      margin-bottom: 0
    &  .divider
      border: none
      height: 1px
      /* Set the hr color */
      color: #DCDCE4 /* old IE */
      background: #DCDCE4 0% 0% no-repeat padding-box
      opacity: 1
      margin: 16px 0
    & .separator
      position: relative
      width: 6px
      margin: 0 3px
      height: 48px
      display: flex
      align-items: flex-end
      &:before
        content: ""
        width: 6px
        height: 1px
        position: absolute
        bottom: 0
        background-color: $icon-hover-color
  & .ant-space-item:nth-child(1)
    width: 112px
    margin-right: 24px
  & .ant-space-item:nth-child(2),
  & .ant-space-item:nth-child(4)
    width: 102px
  & .ant-space-item:nth-child(5)
    width: 12px
    margin-left: 12px
  & .ant-space-item:nth-child(6),
  & .ant-space-item:nth-child(7)
    width: 100%
  & .gameTemplateCurrencySelect
    min-width: 100px
  & .iconBox
    display: flex
    align-items: flex-end
    height: 55px
    width: 20px
    &.disabled
      opacity: 0.5
      pointer-events: none
.defaultGamesItem
  background: rgb(204 204 204 / 25%)
  padding: 24px
  margin-bottom: 12px
.gameTemplateCurrencySelect
  font: 14px/22px "Red Hat Display Regular"
  color: $project-global-color
  width: auto
  & .ant-select-arrow
    transform: rotate(270deg)
  &.ant-select-open
    & .ant-select-arrow
      transform: rotate(0deg)
  & .ant-select-selector
    & .ant-select-selection-item
      display: flex
      align-items: center
      justify-content: center
  & .icon
    width: 18px
    height: 18px
    margin-right: 8px

.overflowContent
  & .defaultGames
    & .ant-space-item:nth-child(5)
      margin-left: 8px