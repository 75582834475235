.partnerResponse-popup
  .ant-modal-header
    .ant-modal-title
      text-align: left
      font: 23px "SF Pro Display Bold"
      letter-spacing: 0
      color: $popup-title-color
      margin: 0
      padding: 0
      line-height: normal
  & .ant-modal-body
    padding: 0 24px 24px 24px
  & .MainModal--footer
    margin-top: 24px
  &--list
    display: flex
    align-items: center
    margin-bottom: 20px
    &.message
      margin-bottom: 8px
    & .title
      text-align: left
      font: 14px/22px "Red Hat Display Bold"
      letter-spacing: 0
      color: $project-global-color
    & .description
      padding-left: 8px
      color: $project-global-color
      text-align: left
      font: 14px/22px "Red Hat Display Regular"
      letter-spacing: 0
      &.withSpacing
        word-spacing: 15px
  & .ant-typography
    margin: 0
    & pre
      background: transparent linear-gradient(180deg, rgba(#F8F8FA, 0.7) 0%, rgba(#F2F2F7, 0.7) 100%) 0 0 no-repeat padding-box
      box-shadow: inset 0 0 1px #1917381F, 0 1px 2px #1917381F
      border-radius: 4px
      border-color: transparent
      text-align: left
      font: 14px/22px "Red Hat Display Regular"
      letter-spacing: 0
      color: $project-global-color
      margin: 0