.radioSwitch
  &.ant-switch-checked
    background-color: #53d37a
.mainRange
  & .ant-slider-track
    background-color: #53d37a
  & .ant-slider-step .ant-slider-dot-active
    border-color: #53d37a
  & .ant-slider-handle
    border: solid 2px #53d37a
  &.ant-slider:hover .ant-slider-track
    background-color: #53d37a
  &.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open)
    border-color: #53d37a
.mainSelectDropDown
  & .ant-select-item
    padding: 0
    min-height: auto
    line-height: normal
  & .checkBox
    min-height: 32px
    padding: 5px 12px 5px 47px
    line-height: 22px
/* checkBox */
.checkBox
  position: relative
  padding-left: 35px
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  text-align: left
  display: flex
  align-items: center
  font:  300 14px/22px "SF Pro Display Regular"
  letter-spacing: 0
  color: $checkboxLabel-color
  & input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0
  & .checkmark
    position: absolute
    left: 12px
    width: 18px
    height: 18px
    background-color:  #FFFFFF
    border: 1px solid $input-border-hover-color
    border-radius: 2px

  & .checkmark:after
    content: ""
    position: absolute
    display: none
  & input:checked ~ .checkmark:after
    display: block
  & .checkmark:after
    transition: 0.3s all ease-in-out
    left: 6px
    top: 2px
    width: 5px
    height: 10px
    border: solid $checkboxLabel-color
    border-width: 0 2px 2px 0
    -webkit-transform: rotate(45deg)
    -ms-transform: rotate(45deg)
    transform: rotate(45deg)
.ant-form-item-has-error .ant-input-group-addon, .ant-form-item-has-error .ant-input-group-addon ~ .ant-input-group-addon
  color: #ff4d4f
  border-color: #ff4d4f
  & .select-after
    border-top: 1px solid #ff4d4f
    border-bottom: 1px solid #ff4d4f
    border-radius: 2px
    border-top-left-radius: 0
    border-bottom-left-radius: 0
  & .ant-select-open .ant-select-selector, & .ant-select-focused .ant-select-selector,&  .ant-select-selector
    color: #ff4d4f
    opacity: 1
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
  height: 30px
.ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-selector
  color: #191738
  opacity: 0.5
.ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-focused .ant-select-selector
  color: #191738
  opacity: 0.5

.numberRangeInput
  & .ant-form-item-control-input-content
    display: flex
    align-items: center
    justify-content: space-between
    border: 1px solid #d9d9d9
    input
      border: unset
    & .separator
      position: relative
      width: 6px
      margin: 6px
      &:before
        content: ""
        width: 6px
        height: 1px
        position: absolute
        left: 50%
        top: 50%
        background-color: #d9d9d9
        transform: translate(-50%, -50%)
