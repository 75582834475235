.proovider-info
    .ant-tooltip-arrow
        display: none
    .ant-tooltip-inner
        width: fit-content
        padding: 16px 16px 16px 16px
        & > div
            width: fit-content
            text-align: left
        p
            white-space: nowrap
            font: 14px/22px "SF Pro Display Regular"
            color: #00A1AF
            margin: 0px
            text-transform: initial
            &:nth-child(1), &:nth-child(2)
                margin-bottom: 4px
            &::before
                content: ""
                width: 10px
                height: 10px
                display: inline-block
                border: 2px solid #000
                border-radius: 50%
                margin-right: 8px
            &.active
                &::before
                    border-color: #53D37A
            &.inactive
                &::before
                    border-color: #F5222D
            &.suspended
                &::before
                    border-color: #FAAD14

.providers
    .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
        &:not(:first-child, &:last-child)
            text-align: center
    .titleArea--title
        .info
            font-size: 18px
            margin-left: 10px
            position: relative
            top: -10px
            .anticon-info-circle
                transition: color 0.2s
            &:hover
                .anticon-info-circle
                    color: #00A1AF !important

    .ant-radio-wrapper-checked
        .ant-radio, .ant-radio-input
            cursor: default

    .ant-table-tbody > tr > td, .ant-table tfoot > tr > td
        padding: 12px 16px
    .ant-radio-inner
        width: 24px
        height: 24px

        &::after
            top: 5px
            left: 5px
            width: 12px
            height: 12px
    .active
        & .ant-radio-checked
            &::after
                border: 1px solid #53D37A
            & .ant-radio-inner
                border-color: #53D37A
                
                &::after
                    background-color: #53D37A

        & .ant-radio:hover .ant-radio-inner, & .ant-radio-input:focus + .ant-radio-inner, & .ant-radi .ant-radio-inner, & .ant-radio-input + .ant-radio-inner
            border-color: #53D37A

    .inactive
        & .ant-radio-checked
            &::after
                border: 1px solid #F5222D
            & .ant-radio-inner
                border-color: #F5222D
                
                &::after
                    background-color: #F5222D

        & .ant-radio:hover .ant-radio-inner, & .ant-radio-input:focus + .ant-radio-inner, & .ant-radi .ant-radio-inner, & .ant-radio-input + .ant-radio-inner
            border-color: #F5222D

    .suspended
        & .ant-radio-checked
            &::after
                border: 1px solid #FAAD14
            & .ant-radio-inner
                border-color: #FAAD14
                
                &::after
                    background-color: #FAAD14

        & .ant-radio:hover .ant-radio-inner, & .ant-radio-input:focus + .ant-radio-inner, & .ant-radi .ant-radio-inner, & .ant-radio-input + .ant-radio-inner
            border-color: #FAAD14