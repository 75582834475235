@import "@assets/sass/base/_colors"
.user-acount-button
  gap: 8px
  align-items: center
  img
    width: 34px
    height: 34px
    background: red
    border-radius: 50%
    background: transparent linear-gradient(180deg, #0050B3 0%, #1890FF 100%) 0% 0% no-repeat padding-box

.drop-down
  display: flex
  flex-direction: column
  min-width: 328px
  &.ant-dropdown-menu
    padding: 24px
  .user-info
    display: flex
    gap: 24px
    align-items: center
    margin-bottom: 8px
    img
      width: 72px
      height: 72px
      object-fit: cover
      border-radius: 50%
    .detals
      h4
        font: 17px/19px "Red Hat Display Regular"
        font-weight: 500
      h5
        font: 14px/19px "Red Hat Display Regular"
  .ant-dropdown-menu-item
    padding: 0px
    &:hover
      background-color: unset

    &:nth-child(2), &:nth-child(3)
      button
        background: transparent linear-gradient(180deg, #F8F8FA 0%, #F2F2F7 100%) 0% 0% no-repeat padding-box
        box-shadow: inset 0px 0px 1px #1917381F, 0px 1px 2px #1917381F
        border-radius: 4px
        width: 100%
        display: flex
        justify-content: flex-start
        align-items: center
        gap: 0 8px
        padding: 12px !important
        font: 15px/18px "Red Hat Display Regular"
        height: auto
        color: $project-global-color
        max-height: 44px
        &:hover
          color: $select-dropdown-title-color

.sinout-popup
  &.ant-modal-confirm .ant-modal-body
    padding: 32px
  .ant-modal-confirm-body
    .ant-modal-confirm-content
      font: 23px/23px "Red Hat Display Regular"
      color: $project-global-color
      text-align: center
      margin-top: 24px
    .ant-modal-confirm-title
      display: flex
      justify-content: center
      align-items: center
      & > span
        display: block
        width: 60px
        height: 60px
        background: transparent linear-gradient(180deg, #0050B3 0%, #1890FF 100%) 0% 0% no-repeat padding-box
        border: 1px solid #00000000
        color: #fff
        border-radius: 50%
        span
          width: 100%
          height: 100%
          display: block
          svg
            width: 26px
            height: 26px
            margin: 50%
            transform: translate(-50%, -50%)
  .ant-modal-confirm-btns
    .ant-btn
      border-radius: 62px
      min-width: 106px